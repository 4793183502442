import SavingsIcon from '@mui/icons-material/Savings';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Button, ButtonProps, Grid, Paper, Typography, styled } from '@mui/material';

function App() {

  const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    fontWeight: 750
  }));

  return (
    <Box sx={{ height: '100vh', overflow: 'hidden' }}>
      <Grid container overflow={'hidden'} justifyContent="center" alignItems="center" rowSpacing={1} >
        <Grid item sm={4} xs={0}></Grid>
        <Grid item sm={4} xs={12}>
          <img src={require('./assets/MATHEUS BRANDÃO RETO 3.png')} alt="correct" style={{ maxWidth: "100%" }} />
        </Grid>
        <Grid item sm={4} xs={0}></Grid>
        <Grid item sm={3} xs={0} />
        <Grid item sm={4} xs={12} >
          <Paper elevation={1} sx={{
            textAlign: 'center',
            padding: 2,
            borderRadius: 6,
            margin: 2,
          }}>
            <Grid container spacing={0} padding={1}>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: {sm: 700, xs: 500}, padding: 0, color: "#120a8f" }}>
                  Fortaleza tem potencial para muito mais.
                </Typography>
              </Grid>
              <Grid item xs={12}>

                <Typography variant="body1" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: {sm: 700, xs: 500}, padding: 0, color: "#120a8f" }}>
                  Vamos construir ideias para nossa cidade?
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ padding: 3 }}>
                <Typography variant="h6" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: {sm: 900, xs: 800}, padding: 0, color: "#120a8f" }}>
                  Faça parte da minha
                </Typography>
                <Typography variant="h6" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: {sm: 900, xs: 800}, padding: 0, color: "#120a8f" }}>
                  pré-campanha a vereador!
                </Typography>
              </Grid>

              <Grid item sm={12} xs={12} style={{ padding: 3 }}>
              </Grid>

              <Grid item sm={3} xs={0}>
              </Grid>

              <Grid item sm={6} xs={6} sx={{padding: {sm: 2 }}}>
                <CustomButton
                  disableElevation
                  size="large"
                  fullWidth
                  variant="contained"
                  color="success"
                  startIcon={<WhatsAppIcon />}
                  href="https://chat.whatsapp.com/LsoUITXbrHr0OsdIbvMGgn"
                  // target="_blank"
                >
                  Participar
                </CustomButton>
              </Grid>

              <Grid item sm={3} xs={1}>
              </Grid>
              
              <Grid item sm={3} xs={0}>
              </Grid>

              <Grid item sm={6} xs={5} sx={{padding: {sm: 2 }}}>
                <CustomButton
                  disableElevation
                  fullWidth
                  size="large"
                  variant="contained"
                  color="success"
                  startIcon={<SavingsIcon />}
                  href="https://www.queroapoiar.com.br/matheusbrandao"
                  // target="_blank"
                  style={{ backgroundColor: "#d9d904" }}
                >
                  Doar
                </CustomButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={3} xs={0} />
        <Grid item justifyContent="flex-end" xs={"auto"}>
          <img src={require('./assets/MatheusBodyShot.png')} alt="corpo" style={{ maxHeight: "100%", objectFit: "cover", position: "relative", bottom: "50px" }} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
